import React from "react";
import styles from "./testimonials.module.scss";
import { Sequel65, Sequel85 } from "../Typography";
import PcOnly from "../PcOnly";

export default function Testimonials() {
  return (
    <div className={styles.testimonials}>
      <img
        className={styles.testimonials__image}
        src={require("../../assets/images/testimonialLeft.webp")}
        alt=""
      />
      <PcOnly>
        <img
          className={styles.testimonials__starLeft}
          src={require("../../assets/images/starsGroup.webp")}
          alt=""
        />
      </PcOnly>
      <img
        className={styles.testimonials__starRight}
        src={require("../../assets/images/starsGroup2.webp")}
        alt=""
      />
      <div className={styles.testimonials__text}>
        <Sequel85 className={styles.testimonials__text__topText}>
          Testimonials
        </Sequel85>

        <Sequel65 className={styles.testimonials__text__bottomText}>
          What Our Members Have To Say
        </Sequel65>
      </div>
    </div>
  );
}
