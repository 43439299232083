import React from "react";
import styles from "./faq.module.scss";
import { Sequel45, Sequel86 } from "../Typography";
import Accordion from "./Accodion";

const faqData = [
  {
    question: "Can I use Preme FNF if I live outside USA?",
    answer: "Yes, we ship worldwide!",
  },
  {
    question: "How long does shipping typically take?",
    answer: "Typically within 24-72 hours from Los Angeles, CA.",
  },
];

export default function Faq() {
  return (
    <div className={styles.faq}>
      <img
        className={styles.faq__leftImage}
        src={require("../../assets/images/starsGroup3.webp")}
        alt=""
      />
      <img
        className={styles.faq__rightImage}
        src={require("../../assets/images/faqRight.webp")}
        alt=""
      />
      <Sequel86 className={styles.faq__topText}>Frequent Questions</Sequel86>
      <Sequel45 className={styles.faq__bottomText}>
        Our 24/7 Support Team Can Answer Any Questions You May Have! Feel Free
        To Join Our Discord!
      </Sequel45>

      <Accordion items={faqData} />
    </div>
  );
}
