import React from "react";

export function Sequel45({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "sequel45",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function Sequel65({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "sequel65",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function Sequel75({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "sequel75",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function Sequel76({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "sequel76",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function Sequel85({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "sequel85",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function Sequel86({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "sequel86",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function TTCommonsRegular({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "TTCommonsRegular",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export function HurmeGeometricSans3({ children, ...props }) {
  return (
    <div
      style={{
        fontFamily: "HurmeGeometricSans3",
      }}
      {...props}
    >
      {children}
    </div>
  );
}
