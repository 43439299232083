import React from "react";
import styles from "./numbers.module.scss";
import { Sequel85 } from "../Typography";
import CountUp from "react-countup";
import MobileOnly from "../MobileOnly";

export default function Numbers() {
  return (
    <>
      <MobileOnly>
        <img
          src={require("../../assets/images/bg.svg").default}
          alt="bg"
          className={"bg numbers__bg"}
        />
      </MobileOnly>
      <img
        className={styles.leftBlur}
        src={require("../../assets/images/blur.webp")}
        alt="blur"
      />
      <img
        className={styles.rightBlur}
        src={require("../../assets/images/blur.webp")}
        alt="blur"
      />
      <div className={styles.numbers}>
        <div>
          <Sequel85 className={styles.numbers__topText}>Over</Sequel85>
          <Sequel85 className={styles.numbers__midText}>
            $
            <CountUp
              useEasing
              end={500000}
              duration={3}
              enableScrollSpy
              redraw={false}
              scrollSpyOnce
            />
          </Sequel85>
          <Sequel85 className={styles.numbers__bottomText}>
            In Member Profit
          </Sequel85>
        </div>
        <div>
          <Sequel85 className={styles.numbers__topText}>Providing</Sequel85>
          <Sequel85 className={styles.numbers__midText}>
            <CountUp
              end={20}
              enableScrollSpy
              redraw={false}
              useEasing
              duration={2.5}
              scrollSpyOnce
            />
            <span>+</span>
          </Sequel85>
          <Sequel85 className={styles.numbers__bottomText}>
            Monthly Backdoors
          </Sequel85>
        </div>
        <div>
          <Sequel85 className={styles.numbers__topText}>Reaching</Sequel85>
          <Sequel85 className={styles.numbers__midText}>
            <CountUp
              end={100}
              duration={2.5}
              enableScrollSpy
              redraw={false}
              scrollSpyOnce
              useEasing
            />
            %
          </Sequel85>
          <Sequel85 className={styles.numbers__bottomText}>
            Member Satisfaction
          </Sequel85>
        </div>
      </div>
    </>
  );
}
