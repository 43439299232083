import React, { useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import styles from "./faq.module.scss";
import { Sequel75, TTCommonsRegular } from "../Typography";
import { Plus } from "../../constants/Svg";

const Accordion = ({ items }) => {
  const [activeIndices, setActiveIndices] = useState([]);

  const handleItemClick = (index) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true,
    amount: 0.5,
  });

  return (
    <div ref={ref} className={styles.accordion}>
      {items.map((item, index) => (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -10 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
          key={index}
          className={styles.accordion__item}
        >
          <Sequel75
            onClick={() => handleItemClick(index)}
            className={styles.accordion__item__question}
          >
            {item.question}
            {!activeIndices.includes(index) ? (
              <Plus fill={!activeIndices.includes(index) ? "#fff" : "#000"} />
            ) : null}
          </Sequel75>
          <motion.div
            key={index}
            initial={{ height: 0 }}
            style={{
              overflow: "hidden",
            }}
            animate={{ height: activeIndices.includes(index) ? "auto" : 0 }}
            transition={{ duration: 0.3 }}
          >
            <TTCommonsRegular className={styles.accordion__item__answer}>
              {item.answer}
            </TTCommonsRegular>
          </motion.div>
        </motion.div>
      ))}
    </div>
  );
};

export default Accordion;
