import React, { useRef } from "react";
import styles from "./clothingBackdoors.module.scss";
import { Sequel45, Sequel65, Sequel85 } from "../Typography";
import Stars from "../Stars";
import SlideUp, { FadeIn } from "../Anmations";
import { useInView } from "framer-motion";

import { motion } from "framer-motion";

export default function ClothingBackdoors() {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: 0.1,
    once: true,
  });

  return (
    <div className={styles.clothingBackdoors}>
      <img
        className={"clothingBackdoors__bg"}
        src={require("../../assets/images/bg.svg").default}
        alt="bg"
      />
      <img
        className={styles.clothingBackdoors__leftImg}
        src={require("../../assets/images/backdoorLeftImg.webp")}
        alt="leftImg"
      />
      <img
        className={styles.clothingBackdoors__rightBlur}
        src={require("../../assets/images/rightBlur.webp")}
        alt="rightBlur"
      />
      <img
        className={styles.clothingBackdoors__currency_image}
        src={require("../../assets/images/blurredCurrency.webp")}
        alt="currency"
      />
      <SlideUp>
        <div className={styles.clothingBackdoors__text}>
          <Sequel85 className={styles.clothingBackdoors__text__topText}>
            Clothing
          </Sequel85>
          <Sequel85 className={styles.clothingBackdoors__text__bottomText}>
            Backdoors
          </Sequel85>
        </div>
      </SlideUp>
      <FadeIn>
        <Sequel45 className={styles.clothingBackdoors__subText}>
          Get{" "}
          <Sequel65 className={styles.clothingBackdoors__subText__sequel65}>
            access
          </Sequel65>{" "}
          to wholesale pricing on brands including: Sp5der, Essentials, Vlone,
          Bape, Eric Emanuel, and much more.
        </Sequel45>
      </FadeIn>
      <motion.img
        ref={ref}
        initial={{ opacity: 0, x: 40 }}
        animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 40 }}
        transition={{ duration: 0.5 }}
        className={styles.clothingBackdoors__rightImg}
        src={require("../../assets/images/backdoorRightImg.webp")}
        alt="rightImg"
      />
      <Stars customClass={styles.clothingBackdoors__stars} />
    </div>
  );
}
