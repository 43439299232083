import React, { useEffect, useState } from "react";
import styles from "./mobileSidebar.module.scss";
import { Ham } from "../../constants/Svg";
import { Sequel65 } from "../Typography";
import { DashBtn } from "../Header";

const Links = [
  {
    name: "Home",
    link: "#",
  },
  {
    name: "Features",
    link: "#features",
  },
  {
    name: "Testimonials",
    link: "#testimonials",
  },
  {
    name: "Faq",
    link: "#faq",
  },
];

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <>
      <div className={styles["toggle-button"]} onClick={toggleSidebar}>
        <Ham />
      </div>
      <div
        className={`${styles["mobile-sidebar"]} ${isOpen ? styles.open : ""}`}
      >
        <div className={styles["links"]}>
          <DashBtn className={styles["dash-btn"]} />
          <ul>
            {Links.map((link, index) => (
              <li key={index} onClick={toggleSidebar}>
                <a href={link.link}>
                  <Sequel65>{link.name}</Sequel65>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
