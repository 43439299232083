export const HeaderLinks = [
  {
    title: "Home",
    link: "#home",
  },
  {
    title: "Features",
    link: "#features",
  },
  {
    title: "Testimonials",
    link: "#testimonials",
  },
  {
    title: "FAQ",
    link: "#faq",
  },
];
