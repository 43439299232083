import React, { useRef, useState } from "react";
import styles from "./header.module.scss";
import { HurmeGeometricSans3 } from "../Typography";
import { HeaderLinks } from "../../constants/headers";
import { motion, useInView } from "framer-motion";

export default function Header() {
  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true,
    amount: 0.5,
  });
  const [active, setActive] = useState(0);

  return (
    <motion.div
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -30 }}
      transition={{ duration: 0.75 }}
      ref={ref}
      className={styles.header}
    >
      <DashBtn className={styles.dashBtn} />
      {HeaderLinks.map((link, index) => (
        <a
          key={link.title}
          onClick={() => setActive(index)}
          href={link.link}
          className={
            active === index ? styles.headerLinkActive : styles.headerLink
          }
        >
          {link.title}
        </a>
      ))}
    </motion.div>
  );
}

export function DashBtn({ className }) {
  return (
    <div className={`${className}`}>
      <HurmeGeometricSans3>Dashboard</HurmeGeometricSans3>
    </div>
  );
}
