import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

export default function SlideUp({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once: true });
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 50 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

export function SlideLeft({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: -40 }}
      animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -40 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

export function SlideRight({ children, props }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once: true });

  return (
    <motion.div
      {...props}
      ref={ref}
      initial={{ opacity: 0, x: 40 }}
      animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 40 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

export const FadeIn = ({ children, id, style }) => {
  const ref = useRef();
  const inView = useInView(ref, {
    once: true,
  });
  return (
    <motion.div
      id={id}
      ref={ref}
      style={{
        position: "relative",
        ...style,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};
