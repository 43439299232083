import React from "react";
import styles from "./horizontalBar.module.scss";
import HeroLogoWrapper from "../HeroLogoWrapper";
import MobileOnly from "../MobileOnly";

export default function HorizontalBar() {
  return (
    <div className={styles.horizontalBar}>
      <div className={styles.horizontalBar__topBar}></div>
      <MobileOnly>
        <HeroLogoWrapper className={styles.horizontalBar__logoWrapper} />
      </MobileOnly>
      <div className={styles.horizontalBar__bottomBar}></div>
    </div>
  );
}
