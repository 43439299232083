import React from "react";
import styles from "./premeLocks.module.scss";
import { Sequel45, Sequel65, Sequel85 } from "../Typography";
import { DotsGrid, PremeLocksSvg } from "../../constants/Svg";
import SlideUp, { SlideLeft } from "../Anmations";

export default function PremeLocks() {
  return (
    <div className={styles.premeLocks}>
      <div>
        <SlideUp>
          <div className={styles.premeLocks__text}>
            <Sequel85 className={styles.premeLocks__text__topText}>
              Preme
            </Sequel85>
            <Sequel85 className={styles.premeLocks__text__bottomText}>
              Locks
            </Sequel85>
          </div>
        </SlideUp>
        <SlideLeft>
          <Sequel45 className={styles.premeLocks__subText}>
            Get exclusive access to{" "}
            <Sequel65 className={styles.premeLocks__subText__sequel65}>
              Preme Locks
            </Sequel65>{" "}
            , our sport pick service which provides the best picks from experts.
          </Sequel45>
        </SlideLeft>
      </div>

      <PremeLocksSvg customClass={styles.premeLocks__svg} />
      <DotsGrid customClass={styles.premeLocks__dots} />
      <img
        className={styles.premeLocks__currency_image}
        src={require("../../assets/images/currency2.webp")}
        alt="bg"
      />
    </div>
  );
}
