import React, { useRef } from "react";
import styles from "./slick.module.scss";
import { Sequel45, Sequel65, Sequel76 } from "../Typography";
import { motion, useInView } from "framer-motion";

export default function SimpleSlider() {
  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true,
  });

  return (
    <div ref={ref} className={styles.slickContainer}>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.5 }}
        transition={{ duration: 0.5, delay: 0.25 }}
        className={`${styles.slick}`}
      >
        <motion.div
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ delay: 0.75, duration: 0.15 }}
          className={styles.bg}
        ></motion.div>
        <div className={styles.slickUser}>
          <img
            className={styles.slickUser__image}
            src={require("../../assets/images/userImg2.webp")}
            alt="blur"
          />
          <Sequel65 className={styles.slickUser__text}>Quinn#2522</Sequel65>
        </div>
        <Sequel45 className={styles.slick__text}>
          “Great group, more for apparel discounts and backdoors than anything
          but overall is amazing. Super easy to make money!”
        </Sequel45>
        <Sequel76 className={styles.slick__date}>
          Member since Dec 2020
        </Sequel76>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.5 }}
        transition={{ duration: 0.5, delay: 0.25 }}
        className={`${styles.slick} ${styles.active}`}
      >
        <motion.div
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ delay: 0.75, duration: 0.15 }}
          className={styles.bg}
        ></motion.div>
        <div className={styles.slickUser}>
          <img
            className={styles.slickUser__image}
            src={require("../../assets/images/userImg1.webp")}
            alt="blur"
          />
          <Sequel65 className={styles.slickUser__text}>Quinn#2522</Sequel65>
        </div>
        <Sequel45 className={styles.slick__text}>
          “I’ve been in Preme FNF for almost 6 months and my experience has been
          nothing short of incredible. I’ve reached over 100% ROI on nearly all
          of the items I’ve purchased. For $60/month, I can confidently say that
          this group is one of the best investments I have ever made.”
        </Sequel45>
        <Sequel76 className={styles.slick__date}>
          Member since Jan 2023
        </Sequel76>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.5 }}
        transition={{ duration: 0.5, delay: 0.25 }}
        className={`${styles.slick}`}
      >
        <motion.div
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ delay: 0.75, duration: 0.15 }}
          className={styles.bg}
        ></motion.div>
        <div className={styles.slickUser}>
          <img
            className={styles.slickUser__image}
            src={require("../../assets/images/userImg3.webp")}
            alt="blur"
          />
          <Sequel65 className={styles.slickUser__text}>Quinn#2522</Sequel65>
        </div>
        <Sequel45 className={styles.slick__text}>
          ’ve been a member since 2021 and its been by far one of my best
          investments. Ive made an average profit of $3-5k every month by using
          the groups backdoor clothing service!
        </Sequel45>
        <Sequel76 className={styles.slick__date}>
          Member since Mar 2021
        </Sequel76>
      </motion.div>
    </div>
  );
}
