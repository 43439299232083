import React from "react";
import styles from "./heroRight.module.scss";
import { DotsGrid, StripedCircle } from "../../constants/Svg";

import { motion } from "framer-motion";

export default function HeroRight() {
  return (
    <div className={styles.heroRight}>
      <div className={styles.heroRight__currency_image_container}>
        <img
          src={require("../../assets/images/blurredCurrency.webp")}
          alt="currency"
        />
        <img
          src={require("../../assets/images/blur.webp")}
          className={styles.heroRight__currency_blur}
          alt="blur"
        />
      </div>
      <div className={styles.heroRight__currency_image_container2}>
        <StripedCircle
          customClass={styles.heroRight__currency_image_container2__circle}
        />
        <img
          className={styles.heroRight__currency_image_container2__currency}
          src={require("../../assets/images/blurredCurrency.webp")}
          alt="currency"
        />
        <motion.img
          style={{
            zIndex: 2,
          }}
          className={styles.heroRight__currency_image_container2__currency2}
          src={require("../../assets/images/currency2.webp")}
          alt="currency"
        />
        <motion.img
          style={{
            zIndex: 1,
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className={styles.heroRight__currency_image_container2__heroImg}
          src={require("../../assets/images/heroImg.webp")}
          alt="currency"
        />
        <motion.img
          style={{
            zIndex: 0,
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className={styles.heroRight__currency_image_container2__mobile}
          src={require("../../assets/images/mobile.webp")}
          alt="currency"
        />
        <DotsGrid
          customClass={styles.heroRight__currency_image_container2__dots}
        />
        <img
          src={require("../../assets/images/blur.webp")}
          className={styles.heroRight__currency_image_container2__smblur}
          alt="blur"
        />
      </div>
    </div>
  );
}
