import React, { lazy, Suspense } from 'react';
import "./App.scss";

import AboutUs from "./components/AboutUs";
import ClothingBackdoors from "./components/ClothingBackdoors";
import { FadeIn } from "./components/Anmations";
import Faq from "./components/Faq";
import HeroLeft from "./components/HeroLeft";
import HeroRight from "./components/HeroRight";
import HorizontalBar from "./components/HorizontalBar";
import Numbers from "./components/Numbers";
import PcOnly from "./components/PcOnly";
import PremeLocks from "./components/PremeLocks";
import SimpleSlider from "./components/Slick";
import Testimonials from "./components/Testimonials";
import MobileOnly from "./components/MobileOnly";
import { motion } from "framer-motion";
import HeroLogoWrapper from "./components/HeroLogoWrapper";
import MobileSidebar from "./components/MobileSidebar";
import { MobileStars1, MobileStars2 } from "./constants/Svg";


const LazyFooter = lazy(()=> import("./components/Footer"));
const LazyHeader = lazy(()=> import("./components/Header"));
const LazyAboutUsMobileImages = lazy(()=> import ("./components/AboutUsMobileImages"));


function App() {
  return (
    <div id="#home" className="App">
      <FadeIn className={"wrapper"}>
        <div className="container">
          <img
            src={require("./assets/images/bg.svg").default}
            alt="bg"
            className={"bg"}
          />
          <MobileOnly>
            <MobileSidebar />
          </MobileOnly>
          <PcOnly>
            <LazyHeader />
          </PcOnly>
          <div className="hero">
            <HeroLeft />
            <PcOnly>
              <HeroRight />
            </PcOnly>
            <MobileOnly>
              <MobileStars1 className={"mob-stars-1"} />
              <motion.img
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.25 }}
                src={require("./assets/images/mobHero.webp")}
                alt="mob-hero"
                className={"mob-hero"}
              />
              <MobileStars2 className={"mob-stars-2"} />
            </MobileOnly>
          </div>
          <PcOnly>
            <HeroLogoWrapper />
          </PcOnly>
        </div>
      </FadeIn>
      <FadeIn
        style={{
          zIndex: -1,
        }}
      >
        <HorizontalBar />
      </FadeIn>
      <FadeIn id="features" className="wrapper">
        <div className="container">
          <ClothingBackdoors />
        </div>
      </FadeIn>
      <FadeIn className="wrapper">
        <div className="container">
          <PremeLocks />
        </div>
      </FadeIn>
      <div
        style={{
          position: "relative",
        }}
        className="wrapper"
      >
        <div className="container">
          <Numbers />
        </div>
      </div>
      <FadeIn className="wrapper">
        <div className="container">
          <AboutUs />
        </div>
      </FadeIn>
      <Suspense fallback= {<div>Loading ...</div>}>
       <LazyAboutUsMobileImages />
      </Suspense>
      <div id="testimonials" className="container">
        <Testimonials />
      </div>
      <div className="container">
        <SimpleSlider />
      </div>
      <div id="faq" className="container">
        <Faq />
      </div>
      <LazyFooter />
    </div>
  );
}

export default App;

export const PremeTextBlack = () => (
  <svg
    width="148"
    height="14"
    viewBox="0 0 148 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1304 0.292603C13.005 0.292603 14.3547 0.66127 15.1795 1.3986C16.0043 2.13594 16.4167 3.27318 16.4167 4.81034C16.4167 6.35999 16.0043 7.50348 15.1795 8.24081C14.3547 8.96565 13.005 9.32807 11.1304 9.32807H4.4569V13.6771H0.182861V0.292603H11.1304ZM10.0431 6.36624C10.618 6.36624 11.0367 6.24751 11.2991 6.01007C11.574 5.77262 11.7115 5.4102 11.7115 4.92281C11.7115 4.42292 11.5803 4.0605 11.3179 3.83555C11.0554 3.59811 10.6305 3.47939 10.0431 3.47939H4.4569V6.36624H10.0431Z"
      fill="#06030C"
    />
    <path
      d="M34.997 13.6771H30.0294L27.4612 9.38431H22.2874V13.6771H18.0133V0.292603H29.4108C31.2603 0.292603 32.5975 0.655021 33.4223 1.37986C34.2472 2.10469 34.6596 3.2107 34.6596 4.69786C34.6596 5.8351 34.4096 6.69741 33.9097 7.28478C33.4099 7.85965 32.6975 8.25956 31.7727 8.48451V8.63447L34.997 13.6771ZM28.3797 6.34749C28.9296 6.34749 29.3233 6.23501 29.5607 6.01007C29.8107 5.78512 29.9356 5.44144 29.9356 4.97905C29.9356 4.51665 29.8107 4.17923 29.5607 3.96678C29.3233 3.74183 28.9296 3.62935 28.3797 3.62935H22.2874V6.34749H28.3797Z"
      fill="#06030C"
    />
    <path
      d="M40.7219 5.4102H50.5072V8.24081H40.7219V10.6028H51.1258V13.6771H36.4479V0.292603H50.9196V3.34817H40.7219V5.4102Z"
      fill="#06030C"
    />
    <path
      d="M72.9982 13.6771H68.7242V4.21047H68.6117L64.6188 13.6771H61.2821L57.1955 4.07925H57.083V13.6771H53.0152V0.292603H59.7262L62.913 8.29705H63.0254L66.156 0.292603H72.9982V13.6771Z"
      fill="#06030C"
    />
    <path
      d="M79.44 5.4102H89.2253V8.24081H79.44V10.6028H89.8439V13.6771H75.166V0.292603H89.6377V3.34817H79.44V5.4102Z"
      fill="#06030C"
    />
    <path
      d="M102.744 3.57311V6.08505H112.548V9.14061H102.744V13.6771H98.4701V0.292603H112.998V3.57311H102.744Z"
      fill="#06030C"
    />
    <path
      d="M131.296 0.292603V13.6771H126.366L118.961 5.22274H118.849V13.6771H114.781V0.292603H119.805L127.097 8.61573H127.21V0.292603H131.296Z"
      fill="#06030C"
    />
    <path
      d="M137.746 3.57311V6.08505H147.55V9.14061H137.746V13.6771H133.472V0.292603H148V3.57311H137.746Z"
      fill="#06030C"
    />
  </svg>
);
