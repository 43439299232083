import React from "react";
import { Star1, Star2, Star3 } from "../../constants/Svg";

import styles from "./stars.module.scss";

import { motion } from "framer-motion";

export default function Stars({ customClass }) {
  return (
    <div className={`${styles.stars} ${customClass}`}>
      <motion.li className={styles.star1}>
        <Star1 />
      </motion.li>
      <motion.li className={styles.star2} initial={"initial"}>
        <Star2 />
      </motion.li>
      <motion.li className={styles.star3} initial={"initial"}>
        <Star3 />
      </motion.li>
    </div>
  );
}
