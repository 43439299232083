import React from "react";
import { PremeText } from "../../constants/Svg";

export default function HeroLogoWrapper({ className }) {
  return (
    <div className={`hero_logo_wrapper ${className}`}>
      <img
        className={"hero_logo"}
        src={require("../../assets/images/logo.webp")}
        alt="hero"
      />
      <PremeText />
    </div>
  );
}
